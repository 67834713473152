import { css } from '@emotion/react'
import { Headline } from '@dfds-ui/react-components'

import { Markdown } from '../Markdown'
import { FlexCard } from '../Card'
import { AspectImage } from '../Image'
import { LinkboxCarouselItemOverlay } from '.'
import { ThemeProvider } from '../../themes'

const ImageWithBanner = (props) => (
  <div
    css={css`
      position: relative;
      overflow: hidden;
    `}
  >
    <AspectImage {...props} aspectRatio={'3/2'} />
    {props.children}
  </div>
)

const LinkboxCarouselItem = ({ item, width }) => {
  const { link, image, contentRef } = item
  return (
    <ThemeProvider theme={link?.destination?.theme} fallback>
      <FlexCard
        width={width}
        link={link}
        media={
          <ImageWithBanner {...image}>
            {(contentRef.fromPriceAmount || contentRef.fromHeadline) && (
              <LinkboxCarouselItemOverlay
                showFromPrice={contentRef.showFromPrice}
                fromPriceAmount={contentRef.fromPriceAmount}
                fromHeadline={contentRef.fromHeadline}
                currency={contentRef.currency}
                placeCurrency={contentRef.placeCurrency}
                pricePostText={contentRef.pricePostText}
              />
            )}
          </ImageWithBanner>
        }
      >
        {contentRef.title && <Headline as={'h2'}>{contentRef.title}</Headline>}
        <Markdown source={contentRef.text} noBottomMargin={true} />
      </FlexCard>
    </ThemeProvider>
  )
}

export default LinkboxCarouselItem
